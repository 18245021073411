import { render, staticRenderFns } from "./SteosVoiceModal.vue?vue&type=template&id=80327fd6&scoped=true"
import script from "./SteosVoiceModal.vue?vue&type=script&setup=true&lang=js"
export * from "./SteosVoiceModal.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SteosVoiceModal.vue?vue&type=style&index=0&id=80327fd6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80327fd6",
  null
  
)

export default component.exports