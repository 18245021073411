<style scoped lang="scss">
  .steos-voice-modal {
    .steos-voice-modal-content {
      width: 580px;
      max-width: 100%;

      .content-text {
        margin-top: 24px;
        white-space: pre-line;
      }
    }
  }
</style>

<template>
  <modal v-model="modalOpened" class="steos-voice-modal" @close="onModalClose">
    <template #title>
      {{ $t('dashboard.banners.steosVoice.modal.title') }}
    </template>
    <template #body-content>
      <div class="steos-voice-modal-content">
        <steos-voice-banner/>
        <div class="content-text">
          {{ $t('dashboard.banners.steosVoice.modal.text') }}
        </div>
      </div>
    </template>
    <template #controls>
      <btn variant="primary-action" :is-loading="steosVoicesActivating" @click="onActivateVoicesClick">
        {{ $t('dashboard.banners.steosVoice.modal.activateButton') }}
      </btn>
      <btn
        variant="secondary-action"
        :to="{ name: 'dashboard-donations-settings-section', params: { section: 'synthesis' } }"
        @click.native="onSettingsClick">
        {{ $t('dashboard.banners.steosVoice.modal.settingsButton') }}
      </btn>
    </template>
  </modal>
</template>

<script setup>
import { onMounted, computed, watch } from 'vue'
import { useSteosVoicesStore } from '@store/steosVoices'
import { storeToRefs } from 'pinia'

import { tmr } from '@src/plugins/tmr'
import { ga } from '@src/plugins/ga'

import { i18n } from '@src/localization/config'

const steosVoicesStore = useSteosVoicesStore()

const {
  steosVoices,
  steosVoicesModalOpened,
  steosVoicesBannerAllowed,
  steosVoicesActivating,
} = storeToRefs(steosVoicesStore)

const {
  fetchSteosVoices,
  hideSteosVoiceModal,
  showSteosVoiceModal,
  activateSteosVoices,
} = steosVoicesStore

import SteosVoiceBanner from './BannersRotation/SteosVoiceBanner/SteosVoiceBanner.vue'
import cookies from '@services/cookies'

const tryShowModal = () => {
  if (steosVoicesBannerAllowed.value && !cookies.getItem('da.dashboard.hideModal.steosVoice')) {
    showSteosVoiceModal()
  }
}

onMounted(async () => {
  await fetchSteosVoices()

  tryShowModal()
})

watch(steosVoicesBannerAllowed, tryShowModal)

const modalOpened = computed({
  get: () => steosVoicesModalOpened.value,
  set(value) {
    !value && hideSteosVoiceModal()
  },
})

const onActivateVoicesClick = async () => {
  tmr.goal({ goal: 'ActivateButtonClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'ActivateButtonClick',
  })

  activateSteosVoices()
    .then(() => {
      tmr.goal({ goal: 'ActivateSuccess' }, {
        banner: 'steos_voice',
      })

      ga.event({
        eventCategory: 'Banner_steos_voice',
        eventAction: 'ActivateSuccess',
      })

      window.daDashboardPushNotification({
        type: 'success',
        text: i18n.t('dashboard.banners.steosVoice.modal.activated'),
      })

      hideSteosVoiceModal()
    })
}

const onSettingsClick = () => {
  tmr.goal({ goal: 'GotoSettingsButtonClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'GotoSettingsButtonClick',
  })

  hideSteosVoiceModal()
}

const onModalClose = () => {
  tmr.goal({ goal: 'CloseButtonClick' }, {
    banner: 'steos_voice',
  })

  ga.event({
    eventCategory: 'Banner_steos_voice',
    eventAction: 'CloseButtonClick',
  })
}

</script>

